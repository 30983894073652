<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 绑定邮箱
 * @Date: 2021-01-06 10:40:59
 * @LastEditors: OBKoro1
 * @LastEditTime: 2021-02-19 11:43:28
 * @FilePath: /yue_quanzhan_h5_new/src/views/user/member/upemail.vue
-->
<template>
  <div class="user_content">
    <van-field v-model="email" placeholder="请填写正确的邮箱地址" />
    <p class="tip">邮箱将作为出团通知书</p>
    <div class="btn" @click="upname">保存</div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Field, Toast } from 'vant'

Vue.use(Field).use(Toast)
import {
  updateemail
} from '@/services/userApi'

export default {
  data() {
    return {
      email: ''
    }
  },
  created() {
    const _self = this
    _self.email = this.$route.query.email
  },
  methods: {
    upname() {
      if (this.email === '') {
        return Toast('不能为空')
      }
      const parm = {
        email: this.email
      }
      updateemail(parm).then(res => {
        if (Number(res.code) === 200) {
          Toast(res.msg)
          this.$router.push({ path: '/perinfo' })
        } else {
          Toast(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.user_content {
  width: 100%;
  height: auto;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f6f6f6;
  padding-bottom: 80px;

  .tip {
    text-align: left;
    padding: 13px;
    color: #999999;
    font-size: 12px;
  }

  .btn {
    width: 95%;
    height: 43px;
    margin: auto;
    background: #E33F44;
    color: #fff;
    line-height: 43px;
    // padding: 12px;
    border-radius: 7px;
  }
}
</style>
